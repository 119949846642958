import { defineStore } from "pinia"
import type { Ref } from "vue"
import { ref } from "vue"
import type { JumpChange } from "~/types/jumpChange"

export const useJumpChangeStore = defineStore("jumpChange", () => {
  const jumpChange: Ref<JumpChange | null> = ref(null)

  return { jumpChange }
})
